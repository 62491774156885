<template>
  <div>
    <vuestic-widget>
      <div class="va-row d">
        <el-input
          style="margin-bottom: 10px; width: 150px;"
          v-model="filters[0].value"
          placeholder="Pesquisar"
        >
          <i
            slot="prefix"
            class="el-input__icon el-icon-search"
          ></i>
        </el-input>
        <div class="row">

          <button
            @click="$router.push({name:'cadastrar-checklist'})"
            class="btn btn-primary btn-micro mr-2"
          >Cadastrar</button>
        </div>
      </div>
      <div class="va-row">
        <div class="flex md12">
          <data-tables
            :data="checklists"
            :filters="filters"
            :table-props="tableProps"
            :action-col="actionCol"
            v-loading="loading"
          >
            <el-table-column
              prop="nome"
              label="Nome"
              sortable="custom"
            />
          </data-tables>

        </div>
      </div>
    </vuestic-widget>

    <vuestic-modal
      :show.sync="show"
      ref="staticModal"
      v-on:ok="deletarChecklist(checklistSelecionada.id)"
    >
      <div slot="title">Excluir</div>
      <div>Você deseja excluir a checklist <b>{{checklistSelecionada.nome}}</b>?</div>
    </vuestic-modal>
  </div>

</template>

<script>
import ordenar from './ordenacao';

export default {

  name: 'lista-checklist',
  data() {
    return {
      loading: false,
      checklists: [],
      show: true,
      checklistSelecionada: [],
      tableProps: {
        stripe: true,
      },
      actionCol: {
        label: 'Editar Checklist / Acesso / Excluir',
        props: {
          align: 'center',
        },
        buttons: [
          {
            props: {
              icon: 'el-icon-edit',
              type: 'primary',
              circle: true,
            },
            handler: (row) => {
              this.$router.push({ name: 'cadastrar-checklist-id', params: { checklist: row.id } });
            },
          },
          {
            props: {
              icon: 'el-icon-key',
              type: 'warning',
              circle: true,
            },
            handler: (row) => {
              this.$router.push({ name: 'acesso', params: { checklist: row.id } });
            },
          },
          {
            props: {
              type: 'danger',
              icon: 'el-icon-delete',
              circle: true,
            },
            handler: (row) => {
              this.checklistSelecionada = row;
              this.showStaticModal();
            },
          },
        ],
      },
      filters: [
        {
          value: '',
          prop: ['nome'],
        },
      ],
    };
  },
  created() {
    this.getChecklist();
  },
  methods: {
    getChecklist() {
      this.loading = true;
      this.$axios.get('/api/checklist.json').then((res) => {
        this.loading = false;
        this.checklists = (res.data).sort(ordenar.ordenarObjetosPorNome);
      });
    },
    showStaticModal() {
      this.$refs.staticModal.open();
    },
    selecionarChecklist(checklist) {
      this.checklistSelecionada = checklist;
    },
    message(type, title) {
      this.$swal({
        toast: true,
        position: 'top-end',
        showCancelButton: false,
        type,
        title,
        showConfirmButton: false,
        timer: 2000,
      });
    },
    deletarChecklist(id) {
      this.$axios.delete(`/api/checklist/${id}.json`).then(() => {
        this.message();
        this.message('success', 'CheckList excluida com Sucesso!');
        this.checklists.forEach((value, index) => {
          if (value.id === id) {
            this.checklists.splice(index, 1);
          }
        });
      }).catch(() => {
        this.message('error', 'Erro ao deletar!!! Verifique se existe(m) cadastro(s) vinculado(s) a este item.');
      });
    },
  },
};
</script>
<style >
.d {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
</style>
